import  { useState, useEffect } from "react";
import clickSound from "../assets/audio/clickSound.mp3";

const useAudio = () => {
  const [audio] = useState(new Audio(clickSound));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(async () => {
    playing ? await audio.play() : await audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return {toggle};
};
export default useAudio;
