import Loadable from "react-loadable";
//@ts-ignore
import {DataLoadSpinner } from '@dibtech/styleguide'


const ProductListComponent: any = Loadable({
  loader: () => import('./QrCodeView/ProductList'),
  loading: DataLoadSpinner,
});

interface Props {}

const QrCode = (props: Props) => {

  return (
    <>
      <div className="flex justify-between place-items-center"></div>
      <ProductListComponent />
    </>
  );
};

export default QrCode;
