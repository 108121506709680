import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { useNavigate, useParams } from 'react-router';
import { QUERY_SETTINGS, WEBSITE_CATEGORY_LISTS, WEBSITE_PRODUCT_LISTS_BY_CATEGORY } from '../queries';
import Loadable from "react-loadable";
import { qrCartVar, qrCartcache, qrCodeSearchVar } from '../../../components/localstate/Cache';

//@ts-ignore
import { DataLoadSpinner } from '@dibtech/styleguide'
import { setLocalKey } from '../../../helpers/sessionKey';
import { GET_QR_CART } from '../../../components/localstate/Cart';
import { toast } from 'react-toastify';
import useAudio from "../../../customhooks/useAudio";
import { useEffect, useState } from 'react';

const CategoryListComponent: any = Loadable({
  loader: () => import('../../../components/QrCode/QrCodeView/CategoryList'),
  loading: DataLoadSpinner,
});

type Props = {}

const CategoryList = (props: any) => {
  const { toggle } = useAudio();
  const { productList = false } = props;
  const params = useParams();
  const navigate = useNavigate();
  const qrCartItems: any[] = useReactiveVar(qrCartVar);
  const qrCodeSearch: any = useReactiveVar(qrCodeSearchVar)
  const [selectedCategoryId, setSelectedCategoryId] = useState()
  //Categories
  const { data: categoryData } = useQuery(WEBSITE_CATEGORY_LISTS, {
    fetchPolicy: "cache-first",
    variables: {
      status: "visible",
      name_Icontains: qrCodeSearch
    }
  });
  const handleCategoryClick = (id: any) => {
    navigate(`/product/${params?.id}/${params?.pk}/${id}`);
    qrCodeSearchVar("")
  };
  const [productData, setProductData] = useState<any>()
  const { data: settingsData } = useQuery(QUERY_SETTINGS)
  //Products
  const [lazyProductQuery, { loading: productLoading, data }] = useLazyQuery(WEBSITE_PRODUCT_LISTS_BY_CATEGORY);

  const handleCategoryProductData = async (values: any) => {
    setSelectedCategoryId(values?.node?.id)
    try {

      const response = await lazyProductQuery({
        fetchPolicy: "cache-first",
        variables: {
          category: selectedCategoryId,
          search: qrCodeSearch,
        },
      });


      setProductData((prevData: any) => ({
        ...prevData,
        [values.node.id]: response.data,
      }));
    } catch (error) {
    }
  };
  useEffect(() => {
    if (categoryData) {
      setSelectedCategoryId(categoryData?.categories?.edges[0]?.node?.id);
    }
  }, [categoryData]);

  useEffect(() => {
    if (selectedCategoryId) {
      handleCategoryProductData({ node: { id: selectedCategoryId } });
    }
  }, [selectedCategoryId]);

  //Product OnClick
  const handleClick = (item: any) => {
    toggle();
    if (item?.modifierCategory?.edges?.length) {
      navigate(
        `/product/modifier/${params?.id}/${params?.pk}/${item?.id}`,
      );
      qrCodeSearchVar("")
    } else {
      let productInCartList = {
        id: item?.id,
        pk: item?.pk,
        contentTypeId: item?.contentTypeId,
        title: item?.title,
        quantity: 1,
        image: item?.media[0] && item?.media[0]?.media,
        price: parseFloat(item?.finalPrice ?? 0).toFixed(2),
        total: parseFloat(item?.finalPrice ?? 0).toFixed(2),
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        modifierGrandTotal: 0,
        modifierTotal: 0
      };
      let previousObject: any = qrCartItems.find(
        (x: any) => x.id === item?.id
      );
      if (previousObject
      ) {
        let newItemList: any = qrCartItems;

        let foundIndex: any = qrCartItems.findIndex(
          (x: any) => x.id === item?.id
        );

        newItemList[foundIndex].quantity =
          previousObject.quantity >= 0
            ? parseFloat(previousObject.quantity) + 1
            : 1;
        newItemList[foundIndex].total =
          parseFloat(item?.finalPrice) + parseFloat(previousObject.total);

        newItemList[foundIndex].isDeleted = false;
        newItemList[foundIndex].isSelected = true;
        newItemList[foundIndex].isModified = true;

        let updateNewItemList: any = newItemList.map(
          (item: any, index: number) =>
            !index === foundIndex
              ? {
                ...item,
                isSelected: false,
              }
              : item,
        );

        qrCartcache.writeQuery({
          query: GET_QR_CART,
          data: {
            qrCart: qrCartVar([...updateNewItemList]),
          },
        });
        setLocalKey("qrCodeItems", JSON.stringify(updateNewItemList))
        toast.success("Quantity increased")
        navigate(`/category/${params?.id}/${params?.pk}`)
      } else {
        let nonPreviousItemList = qrCartItems?.map(
          (item: any) => ({
            ...item,
            isSelected: false,
          }),
        );
        qrCartcache.writeQuery({
          query: GET_QR_CART,
          data: {
            qrCart: qrCartVar([
              ...nonPreviousItemList,
              productInCartList,
            ]),
          },
        });
        setLocalKey("qrCodeItems", JSON.stringify([...nonPreviousItemList, productInCartList]))
        toast.success("New Item Added Successfully")
        navigate(`/category/${params?.id}/${params?.pk}`)
      }
    }

  }

  return (
    <>

      <CategoryListComponent
        categoryData={categoryData}
        handleCategoryProductData={handleCategoryProductData}
        setSelectedCategoryId={setSelectedCategoryId}
        settingsData={settingsData}
        productData={productData ?? []}
        productLoading={productLoading}
        handleClick={handleClick}
        params={params}
      />
    </>
  )
}

export default CategoryList


