import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { DocumentTextIcon, SearchIcon, ShoppingCartIcon, XIcon } from "@heroicons/react/outline";

const Sidebar = (props: any) => {
  const { setSidebarOpen, sidebarOpen, handleClickTitle, receiptData, tableName, qrCodeSearch, qrCodeSearchVar, categoryData, handleCategoryClick } = props
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="absolute inset-0 flex z-20"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col w-screen pt-10 bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="relative text-center border-b-2 pb-4">
                <XIcon
                  className="absolute right-5 h-10 w-10 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setSidebarOpen(false)}
                />
                <h3 className="px-6 text-lg font-bold cursor-pointer" onClick={() => handleClickTitle()}
                  dangerouslySetInnerHTML={{ __html: receiptData?.receiptSetting?.header }}
                >
                </h3>
                <div className="w-fit text-md font-bold mx-auto text-[#525252] mt-2">
                  {tableName?.tableNameByTableId}
                </div>
              </div>
            </Transition.Child>
            <div className="wifull mt-5 px-6 py-1 flex-1 h-0 overflow-auto">
              <div className="flex relative items-center ml-10 text-gray-400 focus-within:text-gray-60">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  className="block w-full h-full pl-8 pr-3 bg-[#F5F5F5] py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-b-transparent sm:text-sm"
                  type={"search"}
                  value={qrCodeSearch}
                  onChange={(e) => qrCodeSearchVar(e.target.value)}
                  placeholder={"Search.."}
                  autoComplete={"off"}
                />
              </div>
              {categoryData?.categories?.edges?.map(
                (item: any, index: number) => (
                  <React.Fragment key={index}>
                    <ul
                      onClick={() => handleCategoryClick(item?.node?.id)}
                      className="w-full relative text-center flex flex-wrap cursor-pointer p-1 mb-0 justify-center border-b-2"
                    >
                      <li className="group px-2 py-2 font-medium rounded-md w-full">
                        <span className="w-full antialiased hover:subpixel-antialiased ">
                          {item?.node?.name}
                        </span>
                      </li>
                    </ul>
                  </React.Fragment>
                ),
              )}
              <div className="text-center mt-10 mb-3">
                <p className=''>Powered By <a href='https://dibtech.com.au/' className='bg-blue-800 text-white p-1'> Dibtech</a></p>
                &copy; Company Name Right Reserved</div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

export default Sidebar