import { Route, Routes } from "react-router-dom";
import QrCodeOrderLayout from "../layouts/QrCodeOrderLayout";
import QrCode from "../pages/QrCode";
import MyOrders from "../pages/QrCode/QrCodeView/MyOrders";
import ProductTypeModal from "../pages/QrCode/QrCodeView/ProductTypeModal";
import TableOrderItems from "../pages/QrCode/QrCodeView/TableOrderItems";
import QrCodeProductList from "../pages/QrCode/QrCodeView/ProductList";
import QrCodeCategoryList from "../pages/QrCode/QrCodeView/CategoryList";
import QrCodeCartItemsLayout from "../layouts/QrCodeCartItemsLayout";


// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {Component}
 */
const Router = () => {
  return (
    <Routes>
      <Route element={<QrCodeOrderLayout />}>
        <Route path={`/`} element={"Please Scan table Qr Code"} />
        <Route
          path={`/table/:id/:pk`}
          element={<TableOrderItems />}
        />
      </Route>
      <Route path={`/myOrders/:id/:pk`} element={<MyOrders />} />
      <Route element={<QrCodeCartItemsLayout />}>
        <Route path={`/:id/:pk`} element={<QrCode />} />
        <Route
          path={`/category/:id/:pk`}
          element={<QrCodeCategoryList />}
        />
        <Route
          path={`/product/modifier/:id/:pk/:categoryId`}
          element={<ProductTypeModal />}
        />
        <Route
          path={`/product/:id/:pk/:categoryId`}
          element={<QrCodeProductList />}
        />

      </Route>
    </Routes>
  );
};

export default Router;
