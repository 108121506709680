import { MenuAlt2Icon, ShoppingCartIcon } from '@heroicons/react/outline'
import React from 'react'

const HeaderOrder = (props: any) => {
  const { receiptData, handleClickTitle, navigate, tableName, setSidebarOpen, qrCodeSearch, qrCodeSearchVar, qrCartItems, params, result } = props
  const handleClickShoppingIcon = () => {
    navigate(`/table/${params?.id}/${params?.pk}`);
  };

  return (
    <div>
      <div className="text-center">
        <h3 className="px-6 text-lg font-bold cursor-pointer" onClick={() => handleClickTitle()}
          dangerouslySetInnerHTML={{ __html: receiptData?.receiptSetting?.header }}
        >
        </h3>
      </div>
      <div className="bg-white p-5 flex justify-center items-center">
        <div className="w-fit text-lg font-bold mx-auto text-[#525252] cursor-pointer" onClick={() => { navigate(`/category/${params?.id}/${params?.pk}`) }}>
          {tableName?.tableNameByTableId}
        </div>
      </div>
      <div className="relative z-10 flex-shrink-0 flex justify-evenly bg-gray-100 text-gray-500 py-3 shadow">
        <button
          type="button"
          className="px-4 border-r-2 border-gray-300"
          onClick={() => setSidebarOpen(true)}
        >
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* SEARCH BAR COMPONENT */}
        <div className="flex relative items-center ml-10 text-gray-400 focus-within:text-gray-60">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
          </div>
          <input
            className="block w-full h-full pl-8 pr-3 bg-transparent py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
            type={"search"}
            value={qrCodeSearch}
            onChange={(e) => qrCodeSearchVar(e.target.value)}
            placeholder={"Search.."}
            autoComplete={"off"}
          />
        </div>

        <div className="relative">
          <ShoppingCartIcon
            className="h-6 w-6 cursor-pointer"
            aria-hidden="true"
            onClick={() => handleClickShoppingIcon()}
          />
          <span className="flex absolute w-[15px] h-[15px] text-sm text-white text-center items-center  justify-center top-[-8px] right-[-8px] bg-red-600 p-2 rounded-full">
            {qrCartItems ? qrCartItems?.length - result?.length : 0}
          </span>
        </div>
      </div>


    </div>
  )
}

export default HeaderOrder