import {InMemoryCache, makeVar} from "@apollo/client";
import { getLocalKey } from "../../helpers/sessionKey";

export const qrCodeSearchVar: any = makeVar("");



// MyOrders State //////  QRCode My Orders
export const myOrdersVar: any = makeVar([]);
export const myOrdersCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        myOrderItems: {
          read() {
            return myOrdersVar();
          },
        },
      },
    },
  },
})


// QR cart code State //////  QRCode cart
export const qrCartVar: any = makeVar(JSON.parse(getLocalKey("qrCodeItems") || "[]"));
export const qrCartcache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        qrCart: {
          read() {
            return qrCartVar();
          },
        },
      },
    },
  },
});

// Price Manipulation  //////  QRCode cart
export const selectedTaxRateVar: any = makeVar(null);
export const calculatedSellingPriceVar: any = makeVar(null);
export const EditProductCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        taxRate: {
          read() {
            return selectedTaxRateVar();
          },
        },
        calculatedSellingPrice: {
          read() {
            return calculatedSellingPriceVar();
          },
        },
      },
    },
  },
});
