import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { DocumentTextIcon, SearchIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import { Outlet, useNavigate, useParams } from "react-router";
import {
  qrCartVar,
  myOrdersCache,
  myOrdersVar,
  qrCodeSearchVar,
} from "../components/localstate/Cache";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { GET_MYORDERS } from "../components/localstate/Cart";
import { POS_SCREEN_TABLE_ITEM_BY_ID, QR_CODE_TABLE_ORDER, TABLE_NAME_BY_TABLE_ID, WEBSITE_CATEGORY_LISTS, GET_RECEIPT, QUERY_SETTINGS } from "../pages/QrCode/queries";
import Sidebar from "./Sidebar";
import OrderButton from "../components/QrCode/QrCodeView/OrderButton";
import { backendErrorDisplay } from "../utils/backendErrorDisplay";
import { setLocalKey } from "../helpers/sessionKey";
import { MODIFY_KITCHEN_ORDER_ITEMS, QRCODE_KITCHEN_ORDER } from "../pages/QrCode/mutation";
import HeaderOrder from "./HeaderOrder";
import SmallScreenModal from "../components/reusable/Modal";

const QrCodeOrderLayout = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data: receiptData } = useQuery(GET_RECEIPT)

  const qrCodeSearch: any = useReactiveVar(qrCodeSearchVar)
  const qrCartItems: any[] = useReactiveVar(qrCartVar);
  const result = qrCartItems.filter((item: any) => item.isDeleted === true);
  const handleClickShoppingIcon = () => {
    navigate(`/table/${params?.id}/${params?.pk}`);
  };
  let cartTotal: number = 0
  qrCartItems?.map((elem: any) => {
    cartTotal = cartTotal + parseFloat(elem?.total) + (elem?.modifierGrandTotal ? parseFloat(elem?.modifierGrandTotal) : 0)
  })
  const handleClickTitle = () => {
    navigate(`/category/${params?.id}/${params?.pk}`)
    qrCodeSearchVar("")
  }
  const handleCategoryClick = (id: any) => {
    qrCodeSearchVar("")
    if (id) {
      navigate(`/product/${params?.id}/${params?.pk}/${id}`);
    } else {
      navigate(`/${params?.id}/${params?.pk}`);
    }
    setSidebarOpen(false)
  };
  //Categories
  const { data: categoryData } = useQuery(WEBSITE_CATEGORY_LISTS, {
    fetchPolicy: "cache-first",
    variables: {
      status: "visible",
      name_Icontains: qrCodeSearch
    }
  });

  //tablewiseData
  const { data: tableWiseData } = useQuery(POS_SCREEN_TABLE_ITEM_BY_ID, {
    // fetchPolicy: "no-cache",
    variables: {
      id: params?.id ?? "",
    },
  });
  //tablewiseData
  const { data: tableWiseDataNext } = useQuery(QR_CODE_TABLE_ORDER, {
    fetchPolicy: "no-cache",
    variables: {
      tableId: params?.pk ?? "",
    },
  });
  const { data: currentTableData } = useQuery(QR_CODE_TABLE_ORDER, {
    fetchPolicy: "network-only",
    variables: {
      tableId: params.pk ?? "",
    }
  })

  const { data: tableName } = useQuery(TABLE_NAME_BY_TABLE_ID, {
    variables: {
      tableId: params.pk ?? "",
    }
  })

  useEffect(() => {
    if (tableWiseData?.restaurantTable?.status === "OCCUPIED") {
      let itemToCart: any[] = [];

      let previousOrderItemsList =
        tableWiseData?.restaurantTable?.currentKitchenOrder || [];

      previousOrderItemsList?.kitchenOrderItems?.edges?.map((item: any) => {
        let modItems: any = [];
        item?.node?.modifier?.map((modItem: any) => {
          modItems.push({
            id: modItem?.product?.id,
            pk: modItem?.product?.pk,
            contentTypeId: modItem?.product?.contentTypeId,
            title: modItem?.product?.title,
            quantity: modItem?.quantity,
            price: modItem?.product?.finalPrice ?? 0,
            total: modItem?.quantity * modItem?.product?.finalPrice,
            isSelected: false,
            isDeleted: false,
          });
        });
        itemToCart.push({
          id: item?.node?.product?.id,
          pk: item?.node?.product?.pk,
          contentTypeId: item?.node?.product?.contentTypeId,
          title: item?.node?.product?.title,
          quantity: item?.node?.quantity,
          price: item?.node?.product?.finalPrice ?? 0,
          total:
            item?.node?.quantity * item?.node?.product?.finalPrice,
          isSelected: false,
          isDeleted: false,
          modifier: modItems,
          image: item?.node?.product?.media[0] && item?.node?.product?.media[0]?.media,
        });

        myOrdersCache.writeQuery({
          query: GET_MYORDERS,
          data: {
            myOrderItems: myOrdersVar(itemToCart),
          }
        })
      },
      );
    }
  }, [tableWiseData, params?.id]);
  const [thankYouState, setThankYouState] = useState<boolean>(false)
  const { data: settingsData } = useQuery(QUERY_SETTINGS)
  const onCloseThankYouModal = () => {
    qrCartVar([])
    navigate(`/category/${params?.id}/${params?.pk}`)
  }
  //Create QrCode Orders section
  const [createQrCodeOrder, { loading: createOrderLoading }] = useMutation(QRCODE_KITCHEN_ORDER, {
    refetchQueries: [{
      query: QR_CODE_TABLE_ORDER,
      fetchPolicy: "network-only",
      variables: {
        tableId: params.pk
      }
    }],
  });

  const [updateKitchenOrder, { loading: updateKitchenOrderLoading }] = useMutation(MODIFY_KITCHEN_ORDER_ITEMS, {
    refetchQueries: [{
      query: QR_CODE_TABLE_ORDER,
      fetchPolicy: "network-only",
      variables: {
        tableId: params.pk ?? ""
      }
    }],
  })

  const handleOrder = async () => {
    const orderList = qrCartItems;
    let orderItems: any = []
    // IF OCCUPIED TABLE DO UPDATE ELSE CREATE NEW ORDER
    if (tableWiseDataNext?.orderItemByTableId?.tableInfo?.status === "occupied") {
      orderList.map((item: any) => {
        let modifierItems: any = [];
        {
          item?.modifier?.map((item: any) => {
            modifierItems.push({
              contentType: item?.contentTypeId,
              objectId: item?.pk,
              quantity: item?.quantity,
              note: "",
            });
          });
        }
        orderItems.push({
          contentType: item?.contentTypeId,
          objectId: item?.pk,
          quantity: item?.quantity,
          note: item?.note,
          modifier: modifierItems,
        });
      });
      const response = await updateKitchenOrder({
        variables: {
          orderObjectId: tableWiseDataNext?.orderItemByTableId?.tableInfo?.kitchenOrder,
          orderItems: orderItems,
        },
      });
      if (response?.data?.modifyKitcheckOrderItems?.success) {
        setThankYouState(true)
        qrCartVar([])
        setLocalKey("qrCodeItems", "[]")
      } else {
        backendErrorDisplay(response?.data?.modifyKitcheckOrderItems?.errors);
      }
    } else {
      let orderInfo = {
        customer: "",
        table: params?.pk ? params.pk : "",
        note: "",
      };
      orderList.map((item: any) => {
        let modifierItems: any = [];
        {
          item?.modifier?.map((item: any) => {
            modifierItems.push({
              contentType: item?.contentTypeId,
              objectId: item?.pk,
              quantity: item?.quantity,
              note: "",
            });
          });
        }
        orderItems.push({
          contentType: item?.contentTypeId,
          objectId: item?.pk,
          quantity: item?.quantity,
          note: item?.note,
          modifier: modifierItems,
        });
      });
      const response = await createQrCodeOrder({
        variables: {
          orderInfo: orderInfo,
          orderItems: orderItems,
        },
      });

      if (response?.data?.createQrCodeKitchenOrder?.success) {
        setThankYouState(true)
        qrCartVar([])
        setLocalKey("qrCodeItems", "[]")
      } else {
        backendErrorDisplay(response?.data?.createQrCodeKitchenOrder?.errors);
      }
    }

  };

  return (<>
    <div className="h-[100vh] bg-white w-[400px] m-auto relative overflow-hidden">
      <HeaderOrder
        receiptData={receiptData}
        handleClickTitle={handleClickTitle}
        navigate={navigate}
        tableName={tableName}
        setSidebarOpen={setSidebarOpen}
        qrCodeSearch={qrCodeSearch}
        qrCodeSearchVar={qrCodeSearchVar}
        handleClickShoppingIcon={handleClickShoppingIcon}
        qrCartItems={qrCartItems}
        params={params}
        result={result} />
      <div className="h-full overflow-auto invis-scroll pb-32 pt-4">
        <Outlet />
      </div>
      <div className="bg-white h-[100px] absolute bottom-0 w-full flex justify-center items-center">
        <div className="w-full">
          {
            qrCartItems.length === 0 ?
              <>
                <div className="text-center mt-10 mb-3">
                  <p className=''>Powered By <a href='https://dibtech.com.au/' className='bg-blue-800 text-white p-1'> Dibtech</a></p>
                  &copy; Company Name Right Reserved</div>
              </> :
              <div className="w-full">
                <div className="w-full  bg-white bottom-0 w-full left-0 flex flex-col justify-center items-center ">
                  <div className="text-center py-2 text-lg">
                    <span className="font-bold">Total:</span> {settingsData?.basicWebsiteConfigurations?.currency} {cartTotal.toFixed(2)}
                  </div>
                </div>
                <OrderButton
                  handleOrder={handleOrder}
                  createOrderLoading={createOrderLoading}
                  updateKitchenOrderLoading={updateKitchenOrderLoading}
                />
              </div>

          }
        </div>
      </div>

    </div >
    <Sidebar setSidebarOpen={setSidebarOpen}
      sidebarOpen={sidebarOpen}
      handleClickTitle={handleClickTitle}
      receiptData={receiptData}
      tableName={tableName}
      qrCodeSearch={qrCodeSearch}
      qrCodeSearchVar={qrCodeSearchVar}
      categoryData={categoryData}
      handleCategoryClick={handleCategoryClick} />
    <SmallScreenModal isOpen={thankYouState} setIsOpen={setThankYouState}
      onCloseModal={onCloseThankYouModal}
    >
      <div className="flex mb-24 flex-col justify-center text-center text-gray-700 ">
        <div className="w-fit mx-auto ">
          <p className="text-[20px] font-semibold">{tableWiseDataNext?.orderItemByTableId?.tableInfo?.tableName}</p>
          <p className="text-[16px]">Order no: {tableWiseDataNext?.orderItemByTableId?.tableInfo?.orderNumber}</p>
        </div>
        <br />
        <p className="w-full text-[16px] border-2 mb-5 rounded-md p-4">
          {
            settingsData?.basicWebsiteConfigurations?.thankYouMessage?.shortDescription ?
              settingsData?.basicWebsiteConfigurations?.thankYouMessage?.shortDescription
              :
              "Thanks For Ordering"
          }
        </p>
        <span
          className="w-full absolute bottom-0 left-0 right-0 text-white font-semibold"
          onClick={() => navigate(`/myOrders/${params?.id}/${params?.pk}`)}>
          <button className="bg-[#22C55E] h-[55px] w-full p-2">My Order</button>
        </span>
      </div>
    </SmallScreenModal>
  </>
  );
};

export default QrCodeOrderLayout;
