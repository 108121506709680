import { useQuery, useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { qrCartVar, qrCartcache, qrCodeSearchVar } from "../../../components/localstate/Cache";
import useAudio from "../../../customhooks/useAudio";
import { QUERY_SETTINGS, WEBSITE_PRODUCT_LISTS_BY_CATEGORY } from "../queries";
import Loadable from "react-loadable";
import { GET_QR_CART } from "../../../components/localstate/Cart";
import { setLocalKey } from "../../../helpers/sessionKey";

//@ts-ignore
import { DataLoadSpinner } from '@dibtech/styleguide'

const ProductListComponent: any = Loadable({
  loader: () => import("../../../components/QrCode/QrCodeView/ProductList"),
  loading: DataLoadSpinner,
});

export default function ProductList(props: any) {
  const { productList = false } = props;

  const params = useParams();
  const navigate = useNavigate();
  const { toggle } = useAudio();

  const qrCartItems: any[] = useReactiveVar(qrCartVar);
  const qrCodeSearch: any = useReactiveVar(qrCodeSearchVar)

  const { data: settingsData } = useQuery(QUERY_SETTINGS)
  //Products
  const { data: productData, loading: productDataLoading } = useQuery(WEBSITE_PRODUCT_LISTS_BY_CATEGORY, {
    fetchPolicy: "cache-first",
    variables: {
      category: productList
        ? []
        : params?.categoryId
          ? [params?.categoryId]
          : [],
      search: qrCodeSearch,
    },
  });

  //Product OnClick
  const handleClick = (item: any) => {
    toggle();
    if (item?.modifierCategory?.edges?.length) {
      navigate(
        `/product/modifier/${params?.id}/${params?.pk}/${item?.id}`,
      );
      qrCodeSearchVar("")
    } else {
      let productInCartList = {
        id: item?.id,
        pk: item?.pk,
        contentTypeId: item?.contentTypeId,
        title: item?.title,
        quantity: 1,
        image: item?.media[0] && item?.media[0]?.media,
        price: parseFloat(item?.finalPrice ?? 0).toFixed(2),
        total: parseFloat(item?.finalPrice ?? 0).toFixed(2),
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        modifierGrandTotal: 0,
        modifierTotal: 0
      };
      let previousObject: any = qrCartItems.find(
        (x: any) => x.id === item?.id
      );
      if (previousObject
      ) {
        let newItemList: any = qrCartItems;

        let foundIndex: any = qrCartItems.findIndex(
          (x: any) => x.id === item?.id
        );

        newItemList[foundIndex].quantity =
          previousObject.quantity >= 0
            ? parseFloat(previousObject.quantity) + 1
            : 1;
        newItemList[foundIndex].total =
          parseFloat(item?.finalPrice) + parseFloat(previousObject.total);

        newItemList[foundIndex].isDeleted = false;
        newItemList[foundIndex].isSelected = true;
        newItemList[foundIndex].isModified = true;

        let updateNewItemList: any = newItemList.map(
          (item: any, index: number) =>
            !index === foundIndex
              ? {
                ...item,
                isSelected: false,
              }
              : item,
        );

        qrCartcache.writeQuery({
          query: GET_QR_CART,
          data: {
            qrCart: qrCartVar([...updateNewItemList]),
          },
        });
        setLocalKey("qrCodeItems", JSON.stringify(updateNewItemList))
        toast.success("Quantity increased")
        navigate(`/category/${params?.id}/${params?.pk}`)
      } else {
        let nonPreviousItemList = qrCartItems?.map(
          (item: any) => ({
            ...item,
            isSelected: false,
          }),
        );
        qrCartcache.writeQuery({
          query: GET_QR_CART,
          data: {
            qrCart: qrCartVar([
              ...nonPreviousItemList,
              productInCartList,
            ]),
          },
        });
        setLocalKey("qrCodeItems", JSON.stringify([...nonPreviousItemList, productInCartList]))
        toast.success("New Item Added Successfully")
        navigate(`/category/${params?.id}/${params?.pk}`)
      }
    }
  };

  return (
    <>
      <ProductListComponent
        settingsData={settingsData}
        productData={productData}
        productDataLoading={productDataLoading}
        handleClick={handleClick}
      />
    </>
  );
}
