import { gql } from "@apollo/client";

export const QRCODE_KITCHEN_ORDER = gql`
mutation($orderInfo:KitchenOrderInput!, $orderItems:[KitchenOrderItemInput]){
  createQrCodeKitchenOrder(input:{
      orderInfo: $orderInfo,
      orderItems:$orderItems
    })
    {
    errors
    success
    kitchenOrder{
      pk
      id
      orderStatus
      customer{
        pk
        id
        user{
          id
          pk
          mobileNumber
          email
          profile{
            pk
            id
            firstName
            lastName
            middleName
          }
        }
      }
      table{
        pk
        id
        status
        capacity
        title
      }
      kitchenOrderItems{
        edges{
          node{
            id
            pk
            contentTypeId
            modifiedKitchenOrderItem{
              id
            }
            quantity
            product{
              id
              pk
              title
              finalPrice
              price{
                costPrice
                sellingPrice
              }
              assignPrinters{
                printer{
                  id
                  pk
                  location
                  port
                  department
                }
              }
            }
            modifiers{
             edges{
              node{
                id
                pk
                quantity
                product{
                  id
                  pk
                  contentTypeId
                  title
                  finalPrice
                  price{
                    sellingPrice
                    costPrice
                  }
                }
              }
            }
            }
          }
        }
      }
    }
  }
}
`;


export const MODIFY_KITCHEN_ORDER_ITEMS = gql`
mutation MODIFY_KITCHEN_ORDER_ITEMS($orderObjectId:String!,$orderItems:[QRCodeModifyKitchenOrderItems]) {
  modifyKitcheckOrderItems(
    input: {
      orderObjectId: $orderObjectId
      orderItems: $orderItems
    }
  ) {
    success
    errors
  }
}
`