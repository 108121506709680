
//@ts-ignore
import {Spinner } from '@dibtech/styleguide'

interface Props {
  handleOrder: any
  createOrderLoading?: boolean
  updateKitchenOrderLoading?: boolean
}

const OrderButton = (props: Props) => {
  const { handleOrder, createOrderLoading, updateKitchenOrderLoading, } = props;
  return (
    <div className="w-full">
      <button onClick={() => handleOrder()}
        className="px-4 py-4 w-full text-white bg-green-500"
        disabled={createOrderLoading || updateKitchenOrderLoading}
      >
        {
          createOrderLoading || updateKitchenOrderLoading ? <Spinner /> : null
        }
        Order
      </button>
    </div>
  );
};

export default OrderButton;
