import { gql } from "@apollo/client";

export const WEBSITE_CATEGORY_LISTS = gql`
query QUERY_CATEGORY($first: Int, $level: Int, $status: String, $name_Icontains:String) {
  categories(first: $first, level: $level, status: $status, name_Icontains:$name_Icontains) {
    edges {
      node {
        id
        pk
        name
        status
        media {
          media
        }
        parent {
          id
          pk
          name
        }
        children(first: 100) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
`;
export const WEBSITE_PRODUCT_LISTS_BY_CATEGORY = gql`
query ($category: [ID], $search: String) {
  products(category: $category, search: $search) {
    edges {
      node {
        id
        pk
        title
        finalPrice
        contentTypeId
        description {
          shortDesc
        }
        media {
          media
        }
        modifierCategory {
          edges {
            node {
              id
              pk
              name
              productsByCategory {
                edges {
                  node {
                    id
                    title
                    contentTypeId
                  }
                }
              }
            }
          }
        }
        isTaxable
        tax{
          rate
        }
      }
    }
  }
}
`;

export const WEBSITE_PRODUCT_LISTS_BY_CATEGORY_PRODUCTS = gql`
query ($id: ID!) {
  product(id: $id) {
    id
    pk
    title
    media {
      media
    }
    contentTypeId
    finalPrice
    tax{
      rate
    }
    modifierCategory {
      edges {
        node {
          id
          pk
          name
          status
          productsByCategory {
            edges {
              node {
                id
                pk
                title
                finalPrice
                tax{
                  rate
                }
                contentTypeId
                media {
                  id
                  media
                }
                description {
                  shortDesc
                  longDesc
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const QR_CODE_TABLE_ORDER = gql`
query ORDER_ITEMS_BY_TABLE_ID($tableId: String!) {
  orderItemByTableId(tableId: $tableId) {
    tableInfo {
      tableId
      status
      tableName
      kitchenOrder
      orderNumber
    }
    orderItems {
      pk
      title
      quantity
      contentType
      objectId
      note
      image
      sellingPrice
      modifiers {
        pk
        title
        quantity
        contentType
        objectId
        note
        sellingPrice
      }
    }
  }
}
`

export const TABLE_NAME_BY_TABLE_ID = gql`
query TABLE_NAME_BY_TABLE_ID($tableId:String!){
  tableNameByTableId(tableId:$tableId)
}
`

export const QUERY_SETTINGS = gql`
query QUERY_SETTINGS{
  basicWebsiteConfigurations{
    id
    pk
    getContentTypeId
    title
    timezone
    currency
    emailFrom
    schema
    taxRate{
      title
      pk
      rate
    }
    serviceCharge{
      title
      pk
    }
    country
    faviconIcon
    logo
    isVirtualKeyboard
    kitchenPrinter{
      pk
      name
      location
      type
    }
    defaultPrinter{
      pk
      name
      location
      type
    }
    initialSetup
    allowSignup
    calendarType
    isAutoPrint
    isProductWisePrint
    thankYouMessage{
      pk
      shortDescription
      longDescription
    }
  }
}
`;

export const GET_RECEIPT = gql`
  query {
    receiptSetting {
      id
      pk
      createdBy
      header
      footer
    }
  }
`;

export const POS_SCREEN_TABLE_ITEM_BY_ID = gql`

query($id:ID!){
  restaurantTable(id:$id){
    id
    pk
    title
    status
    capacity
    note
    currentKitchenOrder{
      pk
      id
      kitchenOrderItems{
        edges{
          node{
            id
            pk
            quantity
            product{
              id
              pk
              contentTypeId
              title
              taxAmount
              finalPrice
              price{
                sellingPrice
                costPrice
              }
              media{
                media
              }
            }
            modifier{
              id
              pk
              quantity
              product{
                id
                pk
                title
                taxAmount
                finalPrice
                price{
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
    }
    tableOrdersKitchen{
      edges{
        node{
          id
          pk
          kitchenOrderItems{
            edges{
              node{
                modifiedKitchenOrderItems{
                  edges{
                    node{
                      id
                      pk
                    }
                  }
                }
                kitchenOrder{
                  id
                  pk
                
                }
                id
                pk
                quantity
                product{
                  id
                  pk
                  contentTypeId
                  title
                  taxAmount
                  finalPrice
                  price{
                    sellingPrice
                    costPrice
                  }
                }
                modifier{
                  id
                  pk
                  quantity
                  product{
                    id
                    pk
                    title
                    taxAmount
                    finalPrice
                    price{
                      sellingPrice
                      costPrice
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;