import { useQuery, useReactiveVar } from "@apollo/client";
import { DocumentTextIcon, SearchIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import { Outlet, useNavigate, useParams } from "react-router";
import {
  qrCartVar,
  myOrdersCache,
  myOrdersVar,
  qrCodeSearchVar,
} from "../components/localstate/Cache";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { GET_MYORDERS } from "../components/localstate/Cart";
import { POS_SCREEN_TABLE_ITEM_BY_ID, QR_CODE_TABLE_ORDER, TABLE_NAME_BY_TABLE_ID, WEBSITE_CATEGORY_LISTS, GET_RECEIPT } from "../pages/QrCode/queries";
import Sidebar from "./Sidebar";
import HeaderOrder from "./HeaderOrder";

const QrCodeCartItemsLayout = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { data: receiptData } = useQuery(GET_RECEIPT)

  const qrCodeSearch: any = useReactiveVar(qrCodeSearchVar)
  const qrCartItems: any[] = useReactiveVar(qrCartVar);
  const result = qrCartItems.filter((item: any) => item.isDeleted === true);


  const handleClickTitle = () => {
    navigate(`/category/${params?.id}/${params?.pk}`)
    qrCodeSearchVar("")
  }
  const handleCategoryClick = (id: any) => {
    qrCodeSearchVar("")
    if (id) {
      navigate(`/product/${params?.id}/${params?.pk}/${id}`);
    } else {
      navigate(`/${params?.id}/${params?.pk}`);
    }
    setSidebarOpen(false)
  };
  //Categories
  const { data: categoryData } = useQuery(WEBSITE_CATEGORY_LISTS, {
    fetchPolicy: "cache-first",
    variables: {
      status: "visible",
      name_Icontains: qrCodeSearch
    }
  });

  //tablewiseData
  const { data: tableWiseData } = useQuery(POS_SCREEN_TABLE_ITEM_BY_ID, {
    // fetchPolicy: "no-cache",
    variables: {
      id: params?.id ?? "",
    },
  });
  const { data: tableName } = useQuery(TABLE_NAME_BY_TABLE_ID, {
    variables: {
      tableId: params.pk ?? "",
    }
  })

  useEffect(() => {
    if (tableWiseData?.restaurantTable?.status === "OCCUPIED") {
      let itemToCart: any[] = [];

      let previousOrderItemsList =
        tableWiseData?.restaurantTable?.currentKitchenOrder || [];

      previousOrderItemsList?.kitchenOrderItems?.edges?.map((item: any) => {
        let modItems: any = [];
        item?.node?.modifier?.map((modItem: any) => {
          modItems.push({
            id: modItem?.product?.id,
            pk: modItem?.product?.pk,
            contentTypeId: modItem?.product?.contentTypeId,
            title: modItem?.product?.title,
            quantity: modItem?.quantity,
            price: modItem?.product?.finalPrice ?? 0,
            total: modItem?.quantity * modItem?.product?.finalPrice,
            isSelected: false,
            isDeleted: false,
          });
        });
        itemToCart.push({
          id: item?.node?.product?.id,
          pk: item?.node?.product?.pk,
          contentTypeId: item?.node?.product?.contentTypeId,
          title: item?.node?.product?.title,
          quantity: item?.node?.quantity,
          price: item?.node?.product?.finalPrice ?? 0,
          total:
            item?.node?.quantity * item?.node?.product?.finalPrice,
          isSelected: false,
          isDeleted: false,
          modifier: modItems,
          image: item?.node?.product?.media[0] && item?.node?.product?.media[0]?.media,
        });

        myOrdersCache.writeQuery({
          query: GET_MYORDERS,
          data: {
            myOrderItems: myOrdersVar(itemToCart),
          }
        })
      },
      );
    }
  }, [tableWiseData, params?.id]);
  return (<>
    <div className="h-[100vh] bg-white w-[400px] m-auto relative overflow-hidden">
      <HeaderOrder
        receiptData={receiptData}
        handleClickTitle={handleClickTitle}
        navigate={navigate}
        tableName={tableName}
        setSidebarOpen={setSidebarOpen}
        qrCodeSearch={qrCodeSearch}
        qrCodeSearchVar={qrCodeSearchVar}

        qrCartItems={qrCartItems}
        params={params}
        result={result} />
      <div className="h-full overflow-auto invis-scroll pb-32 pt-4">
        <Outlet />
      </div>
      <div className="bg-white  absolute bottom-0 w-full flex justify-center items-center">
        {qrCartItems?.length ? <>
          <button className={'px-4 py-4 w-full text-white bg-green-500'} onClick={() => { navigate(`/table/${params?.id}/${params?.pk}`) }}>View Cart</button>
        </> :
          <>
            <div className="text-center mt-10 mb-3">
              <p className=''>Powered By <a href='https://dibtech.com.au/' className='bg-blue-800 text-white p-1'> Dibtech</a></p>
              &copy; Company Name Right Reserved</div>
          </>
        }
      </div>

    </div>
    <Sidebar setSidebarOpen={setSidebarOpen}
      sidebarOpen={sidebarOpen}
      handleClickTitle={handleClickTitle}
      receiptData={receiptData}
      tableName={tableName}
      qrCodeSearch={qrCodeSearch}
      qrCodeSearchVar={qrCodeSearchVar}
      categoryData={categoryData}
      handleCategoryClick={handleCategoryClick} />

  </>
  );
};

export default QrCodeCartItemsLayout;
