import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { qrCartVar, qrCartcache } from "../../../components/localstate/Cache";
import { QRCODE_KITCHEN_ORDER, MODIFY_KITCHEN_ORDER_ITEMS } from "../mutation";
import { backendErrorDisplay } from "../../../utils/backendErrorDisplay";
import Loadable from "react-loadable";

import { QR_CODE_TABLE_ORDER, QUERY_SETTINGS } from "../queries";
import { GET_QR_CART } from "../../../components/localstate/Cart";
import { setLocalKey } from "../../../helpers/sessionKey";

//@ts-ignore
import {DataLoadSpinner } from '@dibtech/styleguide'

const TableOrderItemsComponent: any = Loadable({
  loader: () => import('../../../components/QrCode/QrCodeView/TableOrderItems'),
  loading: DataLoadSpinner,
});

export interface noteStateInterface {
  open: boolean
  note: string
  itemId: any
}

const TableOrderItems = () => {
  const qrCartItems: any[] = useReactiveVar(qrCartVar);
  const inputElement = useRef<any>();
  const params = useParams();
  const navigate = useNavigate()

  const [thankYouState, setThankYouState] = useState<boolean>(false)
  const [noteState, setNoteState] = useState<noteStateInterface>({
    open: false,
    note: "",
    itemId: null
  })

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  //Increase Product Quantity
  const handleOnIncreasingCounter = (
    event: any,
    index: number,
    selectedItem: any,
  ) => {
    let previousObject: any = qrCartItems.find(
      (item: any, itemIndex: number) =>
        item.id === selectedItem.id && itemIndex === index,
    );

    if (previousObject) {
      let newItemList: any = qrCartItems;
      let foundIndex = qrCartItems.findIndex(
        (item: any, itemIndex: number) =>
          item.id === selectedItem.id && itemIndex === index,
      );
      newItemList[foundIndex].quantity = parseFloat(previousObject.quantity) + 1;
      newItemList[foundIndex].total =
        (previousObject.quantity * parseFloat(previousObject?.price)).toFixed(2)
      newItemList[foundIndex].isModified = true;
      newItemList[foundIndex].modifierGrandTotal = (previousObject.modifierTotal * previousObject.quantity).toFixed(2)
      newItemList[foundIndex].modifier = previousObject?.modifier.map((elem: any) => Object.assign(elem, {
        quantity: parseFloat(previousObject.quantity)
      }))
      qrCartcache.writeQuery({
        query: GET_QR_CART,
        data: {
          qrCart: qrCartVar([...newItemList]),
        },
      });
      setLocalKey("qrCodeItems",JSON.stringify(newItemList))
    }
    event.stopPropagation();
  };

  //Decrease Product Quantity
  const handleOnDecreasingCounter = (
    event: any,
    index: number,
    selectedItem: any,
  ) => {
    let previousObject: any = qrCartItems.find(
      (item: any, itemIndex: number) =>
        item.id === selectedItem.id && itemIndex === index,
    );

    if (previousObject) {

      let newItemList: any = qrCartItems;
      let foundIndex = qrCartItems.findIndex(
        (item: any, itemIndex: number) =>
          item.id === selectedItem.id && itemIndex === index,
      );
      newItemList[foundIndex].quantity =
        parseFloat(previousObject.quantity) > 0
          ? parseFloat(previousObject.quantity) - 1
          : 0;
      newItemList[foundIndex].total =
        (previousObject.quantity * parseFloat(previousObject?.price)).toFixed(2)
      newItemList[foundIndex].isModified = true;
      newItemList[foundIndex].modifierGrandTotal = (previousObject.modifierTotal * previousObject.quantity).toFixed(2)
      newItemList[foundIndex].modifier = previousObject?.modifier.map((elem: any) => Object.assign(elem, {
        quantity: parseFloat(previousObject.quantity)
      }))
      qrCartcache.writeQuery({
        query: GET_QR_CART,
        data: {
          qrCart: qrCartVar([...newItemList]),
        },
      });
      setLocalKey("qrCodeItems",JSON.stringify(newItemList))
    }
    event.stopPropagation();
  };
  //remove product from qrCartItems
  const handleRemoveProductFromCart = (
    event: any,
    index: number,
    selectedItem: any,
  ) => {
    let foundIndex = qrCartItems.findIndex((elem: any) => elem.id === selectedItem.id)
    qrCartItems.splice(foundIndex, 1)
    qrCartcache.writeQuery({
      query: GET_QR_CART,
      data: {
        qrCart: qrCartVar([...qrCartItems]),
      },
    });
    setLocalKey("qrCodeItems",JSON.stringify(qrCartItems))
    // }
    event.stopPropagation();
  };

  const handleWriteNote = (selectedItem: any, note: string, action: string) => {
    let previousObject: any = qrCartItems.find(
      (item: any, itemIndex: number) =>
        item.id === selectedItem
    );

    if (previousObject) {
      let newItemList: any = qrCartItems;
      let foundIndex = qrCartItems.findIndex(
        (item: any, itemIndex: number) =>
          item.id === selectedItem
      );
      newItemList[foundIndex].note = action === "save" ? note : ""
      qrCartcache.writeQuery({
        query: GET_QR_CART,
        data: {
          qrCart: qrCartVar([...newItemList]),
        },
      });
      setLocalKey("qrCodeItems",JSON.stringify(newItemList))
    }
  }

  //Create QrCode Orders section
  const [createQrCodeOrder, { loading: createOrderLoading }] = useMutation(QRCODE_KITCHEN_ORDER, {
    refetchQueries: [{
      query: QR_CODE_TABLE_ORDER,
      fetchPolicy: "network-only",
      variables: {
        tableId: params.pk
      }
    }],
  });

  const [updateKitchenOrder, { loading: updateKitchenOrderLoading }] = useMutation(MODIFY_KITCHEN_ORDER_ITEMS, {
    refetchQueries: [{
      query: QR_CODE_TABLE_ORDER,
      fetchPolicy: "network-only",
      variables: {
        tableId: params.pk ?? ""
      }
    }],
  })

  //tablewiseData
  const { data: tableWiseData } = useQuery(QR_CODE_TABLE_ORDER, {
    fetchPolicy: "no-cache",
    variables: {
      tableId: params?.pk ?? "",
    },
  });

  const handleOrder = async () => {
    const orderList = qrCartItems;
    let orderItems: any = []
    // IF OCCUPIED TABLE DO UPDATE ELSE CREATE NEW ORDER
    if (tableWiseData?.orderItemByTableId?.tableInfo?.status === "occupied") {
      orderList.map((item: any) => {
        let modifierItems: any = [];
        {
          item?.modifier?.map((item: any) => {
            modifierItems.push({
              contentType: item?.contentTypeId,
              objectId: item?.pk,
              quantity: item?.quantity,
              note: "",
            });
          });
        }
        orderItems.push({
          contentType: item?.contentTypeId,
          objectId: item?.pk,
          quantity: item?.quantity,
          note: item?.note,
          modifier: modifierItems,
        });
      });
      const response = await updateKitchenOrder({
        variables: {
          orderObjectId: tableWiseData?.orderItemByTableId?.tableInfo?.kitchenOrder,
          orderItems: orderItems,
        },
      });
      if (response?.data?.modifyKitcheckOrderItems?.success) {
        setThankYouState(true)
        qrCartVar([])
        setLocalKey("qrCodeItems","[]")
      } else {
        backendErrorDisplay(response?.data?.modifyKitcheckOrderItems?.errors);
      }
    } else {
      let orderInfo = {
        customer: "",
        table: params?.pk ? params.pk : "",
        note: "",
      };
      orderList.map((item: any) => {
        let modifierItems: any = [];
        {
          item?.modifier?.map((item: any) => {
            modifierItems.push({
              contentType: item?.contentTypeId,
              objectId: item?.pk,
              quantity: item?.quantity,
              note: "",
            });
          });
        }
        orderItems.push({
          contentType: item?.contentTypeId,
          objectId: item?.pk,
          quantity: item?.quantity,
          note: item?.note,
          modifier: modifierItems,
        });
      });
      const response = await createQrCodeOrder({
        variables: {
          orderInfo: orderInfo,
          orderItems: orderItems,
        },
      });
      
      if (response?.data?.createQrCodeKitchenOrder?.success) {
        setThankYouState(true)
        qrCartVar([])
        setLocalKey("qrCodeItems","[]")
      } else {
        backendErrorDisplay(response?.data?.createQrCodeKitchenOrder?.errors);
      }
    }

  };

  return (
    <>
      <TableOrderItemsComponent
        qrCartItems={qrCartItems}
        inputElement={inputElement}
        thankYouState={thankYouState}
        setThankYouState={setThankYouState}
        params={params}
        navigate={navigate}
        settingsData={settingsData}
        handleOnIncreasingCounter={handleOnIncreasingCounter}
        handleOnDecreasingCounter={handleOnDecreasingCounter}
        handleRemoveProductFromCart={handleRemoveProductFromCart}
        handleOrder={handleOrder}
        createOrderLoading={createOrderLoading}
        updateKitchenOrderLoading={updateKitchenOrderLoading}
        tableInfo={tableWiseData?.orderItemByTableId?.tableInfo}
        noteState={noteState}
        setNoteState={setNoteState}
        handleWriteNote={handleWriteNote}
      />
    </>
  );
};

export default TableOrderItems;
