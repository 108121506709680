import { gql } from "@apollo/client";

const GET_MYORDERS = gql`
  query GetMyOrderItems {
    myOrderItems @client
  }
`;

const GET_QR_CART = gql`
  query GetQrCart {
    qrCart @client
  }
`;

export {
  GET_MYORDERS,
  GET_QR_CART
};


