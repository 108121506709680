import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Fragment } from 'react'

type Props = {
  isOpen: any,
  setIsOpen: any
  children: any
  closeObjectProp?: string
  onCloseModal?: any;
  title?: string
}

const SmallScreenModal = (props: Props) => {

  const { children, isOpen, setIsOpen, closeObjectProp, onCloseModal, title } = props

  function closeModal() {
    if (closeObjectProp) {
      setIsOpen((prev: any) => ({ ...prev, [closeObjectProp]: false }))
    } else {
      setIsOpen(false)
    }
    if (onCloseModal) {
      onCloseModal()
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 relative" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {title ?
                  <div className="w-full p-4 relative transform overflow-hidden rounded-2xl bg-white p-5 text-left align-middle shadow-xl transition-all">
                    <div className='absolute top-3 w-full flex justify-between gap-6 '>
                      <span className='w-[90%] flex justify-center text-center font-semibold'>
                        {title}
                      </span>
                      <span className='w-[10%] flex justify-end  mr-10'>
                        <XIcon className='w-6 h-6 text-gray-700 cursor-pointer' onClick={() => closeModal()} />
                      </span>
                    </div>

                    <div className="p-4 mt-5">
                      {children}
                    </div>
                  </div> :
                  <div className="w-full p-4 relative transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                    <span className='w-full flex justify-end mt-2 mr-5'>
                      <XIcon className='w-6 h-6 text-gray-700 cursor-pointer' onClick={() => closeModal()} />
                    </span>
                    <div className="mx-20">
                      {children}
                    </div>
                  </div>
                }
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SmallScreenModal