import { useQuery, useReactiveVar } from '@apollo/client'
import { useNavigate, useParams } from 'react-router';
import Loadable from "react-loadable";
import { GET_RECEIPT, QR_CODE_TABLE_ORDER, QUERY_SETTINGS, TABLE_NAME_BY_TABLE_ID } from "../queries";

//@ts-ignore
import { DataLoadSpinner } from '@dibtech/styleguide'
import { qrCodeSearchVar } from '../../../components/localstate/Cache';
import BackIcon from '../../../assets/Back.svg'
const MyOrdersComponent: any = Loadable({
  loader: () => import('../../../components/QrCode/QrCodeView/MyOrders'),
  loading: DataLoadSpinner,
});

type Props = {}

const MyOrders = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate()
  const { data: settingsData } = useQuery(QUERY_SETTINGS)
  const qrCodeSearch: any = useReactiveVar(qrCodeSearchVar)

  const { data: currentTableData, loading: loadingCurrentTableData } = useQuery(QR_CODE_TABLE_ORDER, {
    fetchPolicy: "network-only",
    variables: {
      tableId: params.pk ?? "",
    }
  })
  const { data: tableName } = useQuery(TABLE_NAME_BY_TABLE_ID, {
    variables: {
      tableId: params.pk ?? "",
    }
  })
  const { data: receiptData } = useQuery(GET_RECEIPT)
  const handleClickTitle = () => {
    navigate(`/category/${params?.id}/${params?.pk}`)
    qrCodeSearchVar("")
  }
  return (
    <>
      <div className="relative max-w-[400px] mx-auto border h-[95vh] invis-scroll overflow-auto pt-10">
        <div className="text-center relative">
          <h3 className="px-6 text-lg font-bold cursor-pointer" onClick={() => handleClickTitle()}
            dangerouslySetInnerHTML={{ __html: receiptData?.receiptSetting?.header }}
          >
          </h3>
          <div className='absolute left-7 top-3 cursor-pointer' onClick={() => { navigate(`/category/${params?.id}/${params?.pk}`) }}>
            <img src={BackIcon} alt='' />
          </div>
          <div className="w-fit text-2xl mb-4 font-bold mx-auto text-[#2A2E36] cursor-pointer">
            {tableName?.tableNameByTableId}
          </div>

        </div>

        <MyOrdersComponent
          params={params}
          navigate={navigate}
          settingsData={settingsData}
          myOrderItems={currentTableData?.orderItemByTableId?.orderItems ?? []}
          tableInfo={currentTableData?.orderItemByTableId?.tableInfo}
          loadingCurrentTableData={loadingCurrentTableData}
        />
      </div>
    </>
  )
}

export default MyOrders