import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { getLocalKey, removeLocalKey } from "./helpers/sessionKey";
import { toast } from "react-toastify";
import { createUploadLink } from "apollo-upload-client";

// import {getLocalKey} from "../utils/localKey";


export const DOWNLOAD_HOSTNAME = "http://localhost:8000/download/"
const httpLink: any = createUploadLink({
  uri: "https://dibposservice.dibsolutions.com.au/graphql",
  credentials: 'same-origin',
})


// const setAuthorizationLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   let token;
//   if (getLocalKey("token")) {
//     token = getLocalKey("token")
//   }
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       Authorization: token ? `Bearer ${token}` : null,
//     },
//   };
// });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // my error handling logic
  console.log(graphQLErrors)
  if (graphQLErrors) {
    for (const { message, locations, path } of graphQLErrors) {
      if (message === "Signature has expired" || message === "Error decoding signature") {
        removeLocalKey("token");
      } else {
        if (!window.location.href.includes("qrCode-Order")) {
          toast.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        }
      }
      // console.log(JSON.parse(message),'message') //In this way we can extract error message from message
      // refetch token , if expire refetch
    }
  }
  if (networkError) {
      toast.error("Please wait...")
  }
});

const defaultOptions: any = {
  // watchQuery: {
  //   fetchPolicy: 'cache-and-network',
  //   errorPolicy: 'ignore',
  // },
    watchQuery: {
      nextFetchPolicy: 'cache-only',
    },
  // query: {
  //   fetchPolicy: 'network-only',
  //   errorPolicy: 'all',
  // },
  // mutate: {
  //   errorPolicy: 'all',
  // },
};

const apolloClient = new ApolloClient({
  ssrMode: typeof window === "undefined",
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
})

export default apolloClient;
