import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { QUERY_SETTINGS, WEBSITE_PRODUCT_LISTS_BY_CATEGORY_PRODUCTS } from "../queries";
import useAudio from "../../../customhooks/useAudio";
import { toast } from "react-toastify";
import { qrCartcache, qrCartVar } from "../../../components/localstate/Cache";
import Loadable from "react-loadable";
import { GET_QR_CART } from "../../../components/localstate/Cart";
import { setLocalKey } from "../../../helpers/sessionKey";

//@ts-ignore
import { DataLoadSpinner } from '@dibtech/styleguide'

const ProductTypeModalComponent: any = Loadable({
  loader: () => import('../../../components/QrCode/QrCodeView/ProductTypeModal'),
  loading: DataLoadSpinner,
});

interface Props { }

const ProductTypeModal = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate()

  const { toggle } = useAudio();
  const qrCartItems: any[] = useReactiveVar(qrCartVar);

  const [selectedItem, setSelectedItem] = useState<any>({
    contentTypeId: "",
    id: "",
    image: null,
    isDeleted: false,
    isSelected: false,
    isModified: true,
    modifierTotal: 0,
    modifierGrandTotal: 0,
    modifier: [],
    pk: 0,
    price: 0,
    quantity: 0,
    title: "",
    total: 0,
    note: ""
  })

  const { data: settingsData } = useQuery(QUERY_SETTINGS)

  //productsByCategoryProduct
  const { data } = useQuery(WEBSITE_PRODUCT_LISTS_BY_CATEGORY_PRODUCTS, {
    variables: {
      id: params.categoryId,
    },
  });
  //Product OnClick
  const handleClick = async (item: any) => {
    toggle();
    if (item) {
      let previousObject: any = qrCartItems.find(
        (x: any) => x.id === item?.id)

      if (previousObject) {
        let newItemList: any = qrCartItems;

        let foundIndex: any = qrCartItems.findIndex(
          (x: any) => x.id === item?.id
        );
        newItemList[foundIndex] = item

        qrCartcache.writeQuery({
          query: GET_QR_CART,
          data: {
            qrCart: qrCartVar([...newItemList]),
          },
        });
        setLocalKey("qrCodeItems", JSON.stringify([newItemList]))
      } else {
        let nonPreviousItemList = qrCartItems?.map(
          (item: any) => ({
            ...item,
            isSelected: false,
          }),
        );
        qrCartcache.writeQuery({
          query: GET_QR_CART,
          data: {
            qrCart: qrCartVar([
              ...nonPreviousItemList,
              item,
            ]),
          },
        });
        setLocalKey("qrCodeItems", JSON.stringify([...nonPreviousItemList, item]))
      }
    }
    toast.success("New Item Added Successfully")
    navigate(`/category/${params.id}/${params.pk}`)
  };

  useEffect(() => {
    let selectedProduct: any = {
      contentTypeId: data?.product?.contentTypeId,
      id: data?.product?.id,
      image: data?.product?.media[0] && data?.product?.media[0]?.media,
      isDeleted: false,
      isSelected: false,
      modifier: [],
      modifierTotal: 0,
      modifierGrandTotal: 0,
      pk: data?.product?.pk,
      price: parseFloat(data?.product?.finalPrice),
      quantity: 1,
      title: data?.product?.title,
      total: parseFloat(data?.product?.finalPrice),
      note: ""
    }
    setSelectedItem(selectedProduct)
  }, [data])

  const handleOnIncreasingCounter = (event: any, index: number, item: any) => {
    setSelectedItem((prev: any) => (
      {
        ...prev,
        quantity: parseInt(prev.quantity) + 1,
        total: (parseInt(item.quantity + 1) * item?.price),
        modifierGrandTotal: prev.modifierTotal * parseInt(item.quantity + 1),
        modifier: prev.modifier.length === 0 ? [] :
          prev.modifier.map((elem: any) =>
            Object.assign(elem, { quantity: parseInt(prev.quantity) + 1 })
          )
      }
    ))
  }

  const handleOnDecreasingCounter = (event: any, index: number, item: any) => {
    setSelectedItem((prev: any) => (
      {
        ...prev,
        quantity: parseInt(prev.quantity) - 1,
        total: (item.quantity - 1) * item?.price,
        modifierGrandTotal: prev.modifierTotal * (item.quantity - 1),
        modifier: prev.modifier.length === 0 ? [] :
          prev.modifier.map((elem: any) =>
            Object.assign(elem, { quantity: parseInt(prev.quantity) - 1 })
          )
      }
    ))
  }

  const handleSelectModifier = (selectedModifier: any) => {
    toggle();
    if (selectedItem.modifier.find((elem: any) => elem.id === selectedModifier.id)) {
      setSelectedItem((prev: any) => (
        {
          ...prev,
          modifierGrandTotal: prev.modifierGrandTotal - parseFloat(selectedModifier.finalPrice) * selectedItem.quantity,
          modifierTotal: prev.modifierTotal - parseFloat(selectedModifier.finalPrice),
          modifier: selectedItem.modifier.filter((elem: any) => elem.id !== selectedModifier.id)
        }
      ))
    } else {
      setSelectedItem((prev: any) => (
        {
          ...prev,
          modifierGrandTotal: prev.modifierGrandTotal + parseFloat(selectedModifier.finalPrice) * selectedItem.quantity,
          modifierTotal: prev.modifierTotal + parseFloat(selectedModifier.finalPrice),
          modifier: [...prev.modifier,
          {
            id: selectedModifier?.id,
            pk: selectedModifier?.pk,
            price: selectedModifier?.finalPrice,
            title: selectedModifier?.title,
            contentTypeId: selectedModifier?.contentTypeId,
            quantity: parseFloat(prev?.quantity)
          }
          ]
        }
      ))
    }
  }

  return (
    <>
      <ProductTypeModalComponent
        settingsData={settingsData}
        data={data}
        handleClick={handleClick}
        handleOnIncreasingCounter={handleOnIncreasingCounter}
        handleOnDecreasingCounter={handleOnDecreasingCounter}
        handleSelectModifier={handleSelectModifier}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </>
  );
};

export default ProductTypeModal;
