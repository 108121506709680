import "./index.css";
import React, { Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

export default function Root() {
  return (
    <BrowserRouter basename="qr-code-order">
      <Suspense fallback={<div>Loading... </div>}>
        <App />
      </Suspense>
    </BrowserRouter>
  );
}
